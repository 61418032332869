<template>

  <div class="row" ref="contentForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  vid="parent_id"
                  rules="required"
                  name="The Parent"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="parent_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="Parent"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Parent'"
                    :list="pageList"
                    :listItemLabel="'label'"
                    :listItemValue="'id'"
                    v-model="formData.parent_id">
                  </fg-select>
                </ValidationProvider>

                <ValidationProvider
                  vid="page_name"
                  rules="required"
                  name="The Page Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Page Name"
                            name="page_name"
                            fou
                            v-model="formData.page_name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="page_heading"
                  rules="required"
                  name="The Page Heading"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Page Heading"
                            name="page_heading"
                            fou
                            v-model="formData.page_heading">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="page_url"
                  rules="required"
                  name="The Page Url"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Page Url"
                            name="page_url"
                            fou
                            v-model="formData.page_url">
                  </fg-input>
                </ValidationProvider>

                <div class="form-group">
                  <label>Banner Image</label>
                    <el-tooltip placement="right" v-if="getBannerImageInfo()">
                      <div slot="content">{{getBannerImageInfo()}}</div>
                      <span style="margin: 0 5px;">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                      </svg>
                      </span>
                    </el-tooltip>
                  <image-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.banner_image"></image-uploader>
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Page Content</label>
                  <editor
                    v-model="formData.page_content"
                    :api-key="editorKey"
                    :init='{
                    height: 400,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                  />
                </div>

              </div>
              <div class="col-md-8 col-sm-12">
                <fg-input type="text"
                          label="Meta Title"
                          name="meta_title"
                          v-model="formData.meta_title">
                </fg-input>

                <fg-text type="text"
                         label="Meta Description"
                         name="meta_description"
                         v-model="formData.meta_description">
                </fg-text>

                <fg-text type="text"
                         label="Meta Keywords"
                         name="meta_keywords"
                         v-model="formData.meta_keywords">
                </fg-text>

                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/pages/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    ImageUploader,
    'editor': Editor
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        page_name: "",
        parent_id: "",
        page_heading: "",
        page_url: "",
        page_content: "",
        banner_image: "",
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
        is_active: true,
      },

      pageList: []

    };
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.contentForm
    });

    this.axios.get("content-pages/builder").then((response) => {

      this.pageList = response.data.pageList;
      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Content Page";
        this.getPage();
      } else {
        this.editMode = false;
        this.formTitle = "Add Content Page";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPage() {
      this.axios.get("content-pages/get/" + this.id).then((response) => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = response.data[key];
        });
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Page Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("content-pages/update/" + this.id, this.formData);
        successMessage = "Page Updated Successfully";
      } else {
        request = this.axios.post("content-pages/create", this.formData);
        successMessage = "Page Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/pages/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo['banner_image_content_page']) {
        return this.mediaInfo["banner_image_content_page"];
      } else {
        return "";
      }
    }

  }
}
</script>
